import { useMemo } from 'react';

import { nextProjectUrl } from '@shared/constants/urls';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { Fire, Gem, Star, Ticket, Trophy } from '@ui/uikit/components/icons/mono';
import {
  Banxa,
  Bscscan,
  Bybit,
  Coingeko,
  Coinmarketcap,
  Gateio,
  Kucoion,
  MagicSquare,
  Pankcakeswap,
} from '@ui/uikit/components/icons/poly';

import { BuySqrNavItems, FeaturedNavItem } from './types';

export const BUY_SQR_BANXA_ITEM = {
  id: 'banxa',
  Icon: Banxa,
  label: 'BANXA',
};

export const buySqrNavItems: BuySqrNavItems = [
  {
    Icon: MagicSquare,
    label: 'Magic Swap',
    id: 'magic-swap',
    href: process.env.NEXT_PUBLIC_SWAP_HOST || 'https://magicsquare.io/swap',
  },
  {
    id: 'buy-with-card',
    title: 'Buy with Card',
    items: [BUY_SQR_BANXA_ITEM],
  },
  {
    id: 'buy-with-crypto',
    title: 'Buy with Crypto',
    items: [
      {
        id: 'KuCoin',
        label: 'KuCoin',
        href: 'https://www.kucoin.com/trade/SQR-USDT?rcode=rMCZ2UD',
        Icon: Kucoion,
      },
      {
        id: 'ByBit',
        label: 'ByBit',
        href: 'https://partner.bybit.com/b/sqrspot',
        Icon: Bybit,
      },
      {
        id: 'PancakeSwap',
        label: 'PancakeSwap',
        href: 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x2B72867c32CF673F7b02d208B26889fEd353B1f8&chain=bsc',
        Icon: Pankcakeswap,
      },
      {
        id: 'Gate.io',
        label: 'Gate.io',
        href: 'https://www.gate.io/en/trade/SQR_USDT?ref=AlBAU1Fd',
        Icon: Gateio,
      },
    ],
  },
  {
    id: 'learn-more',
    title: 'Learn More',
    items: [
      {
        id: 'CoinMarketCap',
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/magic-square/',
        Icon: Coinmarketcap,
      },
      {
        id: 'CoinGecko',
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/magic-square',
        Icon: Coingeko,
      },
      {
        id: 'BSCScan',
        label: 'BSCScan',
        href: 'https://bscscan.com/token/0x2b72867c32cf673f7b02d208b26889fed353b1f8',
        Icon: Bscscan,
      },
    ],
  },
];

export const useFeatureNavItems = () => {
  const featureToggles = useFeatureToggles();

  return useMemo(() => {
    return [
      {
        id: 'reward-pools',
        label: 'Reward Pools',
        shortLabel: 'Pools',
        href: nextProjectUrl.store('/reward-pools'),
        Icon: Star,
        iconClassName: 'text-base-text-orange',
        isHidden: !featureToggles.rewardPools,
      },
      {
        id: 'hot-offers',
        label: 'Hot Offers',
        href: nextProjectUrl.store('/hot-offers'),
        Icon: Fire,
        iconClassName: 'text-base-text-red',
      },
      {
        id: 'magic-board',
        label: 'Magic Board',
        shortLabel: 'Board',
        href: nextProjectUrl.store('/magic-board'),
        Icon: Gem,
        iconClassName: 'text-base-text-blue',
      },
      {
        id: 'campaigns',
        label: 'Campaigns',
        href: nextProjectUrl.launchpad('/campaigns'),
        Icon: Trophy,
        iconClassName: 'text-base-text-orange',
      },
      {
        id: 'magic-raffles',
        label: 'Raffles',
        shortLabel: 'Raffles',
        href: nextProjectUrl.store('/magic-raffles'),
        Icon: Ticket,
        iconClassName: 'text-base-text-green',
        isHidden: !featureToggles.showWeb3Raffle,
      },
    ] as FeaturedNavItem[];
  }, [featureToggles]);
};

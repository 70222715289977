import { Suspense, useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { Search } from '@ui/uikit/components/icons/mono';
import { CrossLg } from '@ui/uikit/components/icons/mono/CrossLg';
import { cn } from '@ui/uikit/utils';

import HeaderSearchBar from './HeaderSearchBar';

const MobileSearch = () => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <button className="msq-btn msq-btn-icon-md msq-btn-outline size-[2.25rem] !rounded-full md-msq:msq-btn-icon-lg xl-msq:hidden">
          <Search className="msq-btn-icon-child-md" />
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          className="msq-dialog msq-dialog-mobile-menu-m msq-dialog-animate"
        >
          <Dialog.Content
            forceMount
            aria-describedby={undefined}
            className={cn(
              'msq-dialog-content sm-msq:msq-dialog-content-sm sm-msq:msq-dialog-content-left',
            )}
          >
            <div className="msq-dialog-header">
              <Dialog.Title className="msq-dialog-title">Search</Dialog.Title>

              <Dialog.Close asChild>
                <button className="msq-btn msq-btn-ghost msq-btn-icon-md msq-dialog-icon-button">
                  <CrossLg className="msq-btn msq-btn-icon-child" />
                </button>
              </Dialog.Close>
            </div>
            <div className="msq-dialog-body">
              <Suspense fallback={<div className="w-full skeleton-block h-10"></div>}>
                <HeaderSearchBar onSeeMore={close} onSelect={close} isMobileHeader={true} />
              </Suspense>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default MobileSearch;
